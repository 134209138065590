export default {
    LOGIN: 'LOGIN',
    INFO: 'INFO',
    LOG_OUT: 'LOG_OUT',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    RESET_PASSWORD: 'RESET_PASSWORD',
    GET_FILTER_LIST_CATEGORY: 'GET_FILTER_LIST_CATEGORY',
    GET_FILTER_LIST_WAREHOUSE: 'GET_FILTER_LIST_WAREHOUSE',
    GET_FILTER_LIST_ACCOUNT: 'GET_FILTER_LIST_ACCOUNT',
    GET_FILTER_LIST_PROVIDER: 'GET_FILTER_LIST_PROVIDER',
};
