import toJS from '../../hoc/ToJS';
import React from 'react';

export const HomePage = () => {
  return (
    <div>

    </div>
  );
};


export default toJS(HomePage);
