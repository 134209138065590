export default {
    GET_LIST_PRODUCTS: 'GET_LIST_PRODUCTS',
    ADD_NEW_PRODUCT: 'ADD_NEW_PRODUCT',
    DELETE_PRODUCT: 'DELETE_PRODUCT',
    DELETE_LIST_PRODUCTS: 'DELETE_LIST_PRODUCTS',
    UPDATE_PRODUCT: 'UPDATE_PRODUCT',
    GET_PRODUCT_DETAILS: 'GET_PRODUCT_DETAILS',
    UPLOAD_IMAGES_FOR_PRODUCT: 'UPLOAD_IMAGES_FOR_PRODUCT',
    DELETE_PRODUCT_IMAGE: 'DELETE_PRODUCT_IMAGE',
    ADD_UPDATE_FLASH_SALE: 'ADD_UPDATE_FLASH_SALE',
};